import { useEffect } from 'react'

function useSetTitle(title: string) {
  useEffect(() => {
    document.title = `Dr. Spitzer - ${title}`
  }, [title])
}

export function setDHTitle(title?: string) {
  const prefix = 'Dr. Spitzer'

  if (title) {
    document.title = `${prefix} - ${title}`
  } else {
    document.title = prefix
  }
}

export default useSetTitle
